// ui
import React, { useState } from "react";
import Alert from "@cloudscape-design/components/alert";
import { Box, Container, Grid, Header, SpaceBetween } from "@cloudscape-design/components";
import Button from "@cloudscape-design/components/button";
import FileUpload from "@cloudscape-design/components/file-upload";
import FormField from "@cloudscape-design/components/form-field";
import { Storage } from 'aws-amplify';

export const DocumentUpload = (props) => {
  const [files, setFiles] = React.useState([]);
  const [isUploading, setIsUploading] = useState(false);
  const [value, setValue] = useState([]);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);


  React.useEffect(() => {
    // Configure the AWS region
    Storage.configure({
      region: 'us-east-1',
      //TODO: Don't hardcode this in the future
      bucket: 'njdemoportalpdfstorage82d7a-main'
    });
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    if (value.length === 0) {
      console.error("No file selected");
      return;
    }
  
    try {
      // Get the first file from the value array
      const file = value[0];
  
      // Get the content type of the file
      const contentType = file.type;
  
      const fileName = `docs/source/${file.name}`;
  
      // Upload the file using the Amplify Storage API
      setIsUploading(true);
      const result = await Storage.put(fileName, file, {
        contentType,
        level: 'private',
        onProgress: (progress) => console.log(`Progress: ${progress.loaded}/${progress.total}`),
      });
      console.log(`File uploaded successfully: ${fileName}`);
      // Clear the file input after successful upload
      setValue([]);
      // Show success message
      setShowSuccessMessage(true);
      // Hide success message after 5 seconds
      setTimeout(() => setShowSuccessMessage(false), 5000);
    } catch (error) {
      console.error("Error uploading file:", error);
    } finally {
      setIsUploading(false);
    }
  };
  
  

	return (
    <>
      <SpaceBetween size="s">
        <Container header={<Header variant="h2">Document Upload</Header>}>
        {showSuccessMessage && (
          <Alert type="success" dismissible onDismiss={() => setShowSuccessMessage(false)}>
            File uploaded successfully!
          </Alert>
        )}
          <Grid gridDefinition={[{ colspan: 10 }, { colspan: 10 }]}>
            <Box>
              <b>Upload a document to the Enterprise Document Management system.</b>
              <br /><br />
              The uploaded documents will be stored in an Amazon S3 bucket, and will be processed for translation and search shortly after upload.
              <br /><br />
              <b>As a reminder:</b>
               <ul>
                <li>Do not share personally identifiable information (PII) about residents, colleagues, or yourself.</li>
                <li>Do not share confidential or sensitive content, nor transcribe or summarize meetings where sensitive topics are discussed.</li>
                <li>In short: Do not share any information that you wouldn't share publicly.</li>
               </ul>
            </Box>
            <form onSubmit={handleSubmit}>
              <FormField
                label="Select File"
                description="Choose the file that you would like to upload to the Enterprise Document Management system"
              >
                <FileUpload
                  onChange={({ detail }) => setValue(detail.value)}
                  value={value}
                  i18nStrings={{
                    uploadButtonText: e =>
                      e ? "Choose files" : "Choose file",
                    dropzoneText: e =>
                      e
                        ? "Drop files to upload"
                        : "Drop file to upload",
                    removeFileAriaLabel: e =>
                      `Remove file ${e + 1}`,
                    limitShowFewer: "Show fewer files",
                    limitShowMore: "Show more files",
                    errorIconAriaLabel: "Error"
                  }}
                  showFileLastModified
                  showFileSize
                  showFileThumbnail
                  tokenLimit={3}
                  constraintText="Supported document type: Word Document, PDF"
                />
              </FormField>
              <br /><br />
              <Button type="submit" variant="primary" disabled={isUploading || value.length === 0}>
              {isUploading ? "Uploading..." : "Submit"}
            </Button>
            </form>
          </Grid>
        </Container>
      </SpaceBetween>
    </>
  );
};