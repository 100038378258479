// ui
import React from "react";
import { Box, Container, Grid, Header, SpaceBetween } from "@cloudscape-design/components";

export const DocumentHome = (props) => {

  // need to change the fixed width to be responsible
	return (
    <>
      <SpaceBetween size="s">
        <Container header={<Header variant="h2">Documents</Header>}>
          <Grid gridDefinition={[{ colspan: 10 }, { colspan: 10 }]}>
            <Box>
              <center>
              <img src="" alt={ props.user } width="100px" id="avatar" /><br/>
              </center>
              Are you looking to demonstrate AWS AI/ML capabilities (such as Translation of documents & real time communication) or Enterprise Document Management with GenAI? Then the Nigel Demo Suite may be the tool you are looking for.,  Questions? Email Terell Moore
              (<a href="mailto:terellmo@amazon.com"  rel="noreferrer" target="_blank">terellmo@amazon.com</a>). <br /><br />
              <b>In addition: </b> in order to ensure responsible and safe use, please follow these guidelines: <br />
               <ul>
                <li>Do not share personally identifiable information (PII) about residents, colleagues, or yourself.</li>
                <li>Do not share confidential or sensitive content, nor transcribe or summarize meetings where sensitive topics are discussed.</li>
                <li>In short: Do not share any information that you wouldn't share publicly.</li>
               </ul>
            </Box>
          </Grid>
        </Container>
        Put component here
      </SpaceBetween>
    </>
	);
};