// ui
import React, { useState } from "react";
import { SideNavigation, TopNavigation } from "@cloudscape-design/components";

// components
import { Auth } from 'aws-amplify';

export function NavigationBar ({activeHref, setActiveHref}) {
  const [email, setEmail] = useState('User');
  getUserInfo().then((user) => setEmail(user.attributes.email));

  const itemClickHandler = (e) => {
    e.preventDefault();
    if (e.detail.id === "signout") {
      signOut();
    }
    else if (e.detail.id === "profile") {
      setActiveHref(e.detail.href);
    }
  };

  async function signOut() {
    try {
      await Auth.signOut({ global: true });
    }
    catch (err) {
      console.log('error signing out: ', err);
    }
  }

  async function getUserInfo() {
    try {
      return Auth.currentUserInfo();
    }
    catch (err) {
      console.log('error when getting user info: ', err);
    }
  }

  return (
    <TopNavigation
      identity={{
        href: "/",
        title: "Nigel Demo Suite",
      }}
      utilities={[
        {
          type: "menu-dropdown",
          text: email,
          description: "",
          iconName: "user-profile",
          onItemClick: itemClickHandler,
          items: [
            {
              id: "menu-group",
              text: "Service",
              items: [
                { id: "profile", text: "Profile", href: "#/profile", },
              ]
            },
            { id: "signout", text: "Sign out" }
          ]
        }
      ]}
      i18nStrings={{
        searchIconAriaLabel: "Search",
        searchDismissIconAriaLabel: "Close search",
        overflowMenuTriggerText: "More",
        overflowMenuTitleText: "All",
        overflowMenuBackIconAriaLabel: "Back",
        overflowMenuDismissIconAriaLabel: "Close menu"
      }}
    />
  );
}

export function Navigation ({activeHref, setActiveHref, isAdmin}) {
  const menu = isAdmin ? [
    { type: "link", text: "Home", href: "/" },
    {
    type: 'section',
    text: 'AI/ML',
    items: [
      { type: "link", text: "QnABot", href: "/aiml/qnabot" }
      ],
    },
    {
    type: 'section',
    text: 'Media Services',
    items: [
      { type: "link", text: "Live Streaming", href: "/media/livestreaming" }
      ],
    },
    {
    type: 'section',
    text: 'Business & Communication',
    items: [
      { type: "link", text: "Live Chat", href: "/comms/livechat" }
      ],
    },
    
    {
    type: 'section',
    text: 'Enterprise Document Management',
    items: [
      {
          type: "link-group",
          text: "Documents",
          href: "/docs/",
          items: [
            { type: "link", text: "List", href: "/docs/list" },
            { type: "link", text: "Search", href: "/docs/search" },
            { type: "link", text: "Upload", href: "/docs/upload" }
          ]
        },
        { type: "link", text: "Translate", href: "/docs/translate" }
      ],
    },
    { type: 'divider' },
    { type: "link", text: "Admin", href: "#/admin" },
  ] : [
    { type: "link", text: "Home", href: "/" },
    {
    type: 'section',
    text: 'AI/ML',
    items: [
      { type: "link", text: "QnABot", href: "/aiml/qnabot" }
      ],
    },
    {
    type: 'section',
    text: 'Media Services',
    items: [
      { type: "link", text: "Live Streaming", href: "/media/livestreaming" }
      ],
    },
    {
    type: 'section',
    text: 'Business & Communication',
    items: [
      { type: "link", text: "Live Chat", href: "/comms/livechat" }
      ],
    },
    
    {
    type: 'section',
    text: 'Enterprise Document Management',
    items: [
      {
          type: "link-group",
          text: "Documents",
          href: "/docs/",
          items: [
            { type: "link", text: "List", href: "/docs/list" },
            { type: "link", text: "Search", href: "/docs/search" },
            { type: "link", text: "Upload", href: "/docs/upload" }
          ]
        },
        { type: "link", text: "Translate", href: "/docs/translate" }
      ],
    }
  ];

  return (
    <SideNavigation
        activeHref={activeHref}
        onFollow={e => {
          e.preventDefault();
          setActiveHref(e.detail.href);
          window.location.href = e.detail.href;
        }}
        items={menu}
      />
  );
}
