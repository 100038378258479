import React from 'react';

const LivestreamingComponent = () => {
  return (
    <iframe
      src="https://d1usx2i4zdnhoz.cloudfront.net/"
      style={{ width: '100%', height: '600px' }}
      title="Iframe Example"
    />
  );
};

export { LivestreamingComponent };