import React from 'react';

const LiveChatComponent = () => {
  return (
    <iframe
      src="https://d2ht1yblzrfax1.cloudfront.net/index.html"
      style={{ width: '100%', height: '600px' }}
      title="Iframe Example"
    />
  );
};

export { LiveChatComponent };