// ui
import React, { useRef } from 'react';
import "@cloudscape-design/global-styles/index.css"
import { withAuthenticator } from '@aws-amplify/ui-react';
import { AppLayout, ContentLayout, Header } from "@cloudscape-design/components";
import * as ReactDOM from "react-dom";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

// components
import { Navigation, NavigationBar } from './component/Navigation';
import { Home } from './component/Home';
import { Translation } from './component/Translation';
import { Chatbot } from './component/Chatbot';
import { Livechat } from './component/Livechat';
import { DocumentHome } from './component/Documents/DocumentHome';
import { DocumentUpload } from './component/Documents/DocumentUpload';
import { EnterpriseDocumentManagement } from './component/EnterpriseDocumentManagement';
import { Livestreaming } from './component/Livestreaming';

function App({ signOut, user }) {
  const appLayout = useRef();
  const [activeHref, setActiveHref] = React.useState("#/home");
  const groups = user.signInUserSession.idToken.payload["cognito:groups"];

  const router = createBrowserRouter([
    {
      path: "/",
      element: <Home user={user.username} />
    },
    {
      path: "/aiml/qnabot",
      element: <Chatbot />
    },
    {
      path: "/media/livestreaming",
      element: <Livestreaming />
    },
    {
      path: "/comms/livechat",
      element: <Livechat />
    },
    {
      path: "/docs/",
      element: <DocumentHome />
    },
    {
      path: "/docs/list",
      element: <EnterpriseDocumentManagement />
    },
    {
      path: "/docs/upload",
      element: <DocumentUpload />
    }
  ]);

  return (
    <>
      <NavigationBar activeHref={activeHref} setActiveHref={setActiveHref} />
      <AppLayout
        ref={appLayout}
        navigation={<Navigation activeHref={activeHref} setActiveHref={setActiveHref} isAdmin={groups !== undefined && groups.includes("admin")} />}
        content={
          <ContentLayout header={<Header variant="h1" />}>
            <RouterProvider router={router} />
          </ContentLayout>
        }
      />
    </>
  );
}

export default withAuthenticator(App, { hideSignUp: true })
