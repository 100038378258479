// ui
import React from "react";
import peccy from "../static/images/state_of_new_jersey_logo.jpg"
import "../static/css/ProfileCard.css"
import { Box, Container, Grid, Header, SpaceBetween } from "@cloudscape-design/components";

// component
//import { documentsOverview } from './EnterpriseDocumentManagement/widgets';

export const EnterpriseDocumentManagement = (props) => {

  // need to change the fixed width to be responsible
	return (
    <>
      <SpaceBetween size="s">
        <Container header={<Header variant="h2">Documents</Header>}>
          <Grid gridDefinition={[{ colspan: 10 }, { colspan: 10 }]}>
            <Box>
              <center>
              <img src={ peccy } alt={ props.user } width="100px" id="avatar" /><br/>
              </center>
              This is a demo environment to showcase AWS technologies to the State of New Jersey. This is not an environment hosted by the State of New Jersey and does not contain
              any proprietary State information. Do not, under any circumstance, import proprietary or confidential data into this demo application. Questions? Email Terell Moore
              (<a href="mailto:terellmo@amazon.com"  rel="noreferrer" target="_blank">terellmo@amazon.com</a>). <br /><br />
              <b>In addition: </b> in order to ensure responsible and safe use, please follow these guidelines: <br />
               <ul>
                <li>Do not share personally identifiable information (PII) about residents, colleagues, or yourself.</li>
                <li>Do not share confidential or sensitive content, nor transcribe or summarize meetings where sensitive topics are discussed.</li>
                <li>In short: Do not share any information that you wouldn't share publicly.</li>
               </ul>
            </Box>
          </Grid>
        </Container>
        Put component here
      </SpaceBetween>
    </>
	);
};