// ui
import React from "react";
import peccy from "../static/images/state_of_new_jersey_logo.jpg"
import "../static/css/ProfileCard.css"
import { Box, Container, Grid, Header, SpaceBetween } from "@cloudscape-design/components";

// component
import { LivestreamingComponent } from "./LivestreamingComponent"

export const Livestreaming = (props) => {

  // need to change the fixed width to be responsible
	return (
    <>
      <SpaceBetween size="s">
        <Container header={<Header variant="h2">Live Streaming</Header>}>
          <LivestreamingComponent />
        </Container>
      </SpaceBetween>
    </>
	);
};