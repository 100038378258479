// ui
import React from "react";
import NigelLogo from "../static/images/nigel-full-cropped.png"
import "../static/css/ProfileCard.css"
import {
  Box, Container,
  Grid, Header, SpaceBetween
} from "@cloudscape-design/components";

export const Home = (props) => {
  // need to change the fixed width to be responsible
	return (
    <>
      <SpaceBetween size="s">
        <Container header={<Header variant="h2">Welcome to the Nigel Demo Suite</Header>}>
          <Grid gridDefinition={[{ colspan: 10 }, { colspan: 10 }]}>
            <Box>
              <center>
              <img src={ NigelLogo } alt={ props.user } width="256px" id="avatar" /><br/>
              </center>
              <b>Are you looking to demonstrate AWS AI/ML capabilities (such as Translation of documents & real time communication) or Enterprise Document Management with GenAI? Then the Nigel Demo Suite may be the tool you are looking for.</b> 
              
              <br /><br />
              Use the left navigation menu to explore the demos that are available in this Demo Suite.
              <br /><br />
              If you have any questions or need help, please contact Terell Moore (<a href="mailto:terellmo@amazon.com"  rel="noreferrer" target="_blank">terellmo@amazon.com</a>). <br /><br />
              <b>Some basic house rules: </b> in order to ensure responsible and safe use, please follow the below guidelines. <br />
               <ul>
                <li>Do not share personally identifiable information (PII) about customers, colleagues, or yourself.</li>
                <li>Do not share confidential or sensitive content of any type.</li>
                <li>In short: Do not share any information that you wouldn't share publicly.</li>
               </ul>
            </Box>
          </Grid>
        </Container>
      </SpaceBetween>
    </>
	);
};